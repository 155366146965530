import React, { useState, useRef } from "react";
import Password from "./Password/Password";
import Data from "./Data/Data";
import "./rs.css";
import { ParallaxProvider } from "react-scroll-parallax";
import starsBackground from "../starsBackground.svg";

const PASSWORD = "motiki bubu";

export default function Rs() {
  const [password, setpassword] = useState("");
  const [isPasswordValid, setisPasswordValid] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const audioRef = useRef(null);

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isAudioPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsAudioPlaying(!isAudioPlaying);
    }
  };


  const passwordChangeHandler = (value) => {
    setpassword(value);
  };

  const onSubmitHandler = () => {
    if (password === PASSWORD) {
      setisPasswordValid(true);
      togglePlayPause()
    }
  };

  console.log(audioRef.current)
  return (
    <div
      id="test"
      style={{
        background: "#253237",
        backgroundImage: `url(${starsBackground})`,
      }}
    >
      <div>
        <audio ref={audioRef} loop>
          <source src="/rsAssets/Perfect-Acoustic.mp3" type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </div>
      {isPasswordValid ? (
        <>
          <ParallaxProvider>
            <Data isAudioPlaying={isAudioPlaying} togglePlayPause={togglePlayPause}/>
          </ParallaxProvider>
        </>
      ) : (
        <Password
          password={password}
          passwordChangeHandler={passwordChangeHandler}
          onSubmitHandler={onSubmitHandler}
        />
      )}
    </div>
  );
}
