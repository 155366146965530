import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import content from "../data";

export default function Contact() {
  return (
    <div
      className=" min-h-screen  flex justify-center items-center  "
      id="contact"
    >
      <div
        style={{
          minHeight: "50vh",
          background: "#091C29",
        }}
        className="w-full md:w-4/5 md:rounded-xl shadow-2xl flex md:flex-row flex-col-reverse justify-center items-center mt-20 md:mt-0"
      >
        <img
        src={content.dp}
        alt="dp"
        width="300px"
        className="my-10 mx-auto rounded-2xl"
        >
        </img>
        {/* <LazyLoadImage
          effect="blur"
          src={content.dp}
          alt="dp"
          width="300px"
          className="my-10 mx-auto rounded-2xl"
        /> */}
        <div className="font-dosis w-4/5 md:w-2/5 mt-5 mx-auto">
          <h1 className=" text-white text-5xl font-bold fl">
            {content.contact.title}
          </h1>
          <p className=" text-white text-2xl">
            {content.contact.desc}
            <br />
            <br />
            {content.contact.text[0]}
          </p>
          <div>
            {content.contact.socials.map((social, index) => {
              return (
                <a
                  href={social.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="m-2"
                >
                  <button>
                    <img
                    className="m-2"
                    width="50px"
                    key={index}
                    src={social.img}
                    alt={social.alt}
                    >
                    
                    </img>
                    {/* <LazyLoadImage
                      effect="blur"
                      className="m-2"
                      width="50px"
                      key={index}
                      src={social.img}
                      alt={social.alt}
                    /> */}
                  </button>
                </a>
              );
            })}
          </div>
          <p className="text-white text-2xl">{content.contact.outro}</p>
          <h1 className="text-white text-3xl font-bold float-right fl my-4">
            {content.nav.logo}
            <span className="h-3 w-3 bg-red-400 inline-block ml-2 rounded-full" />
          </h1>
        </div>
      </div>
    </div>
  );
}
