import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import content from "../data";
import Project from "./Project";

export default function Projects() {
  // const animated = useWindowPosition("header", 0.6);
  return (
    <div
      className="min-h-screen flex justify-center items-center flex-col"
      style={{
        background: "#FEFEFE",
      }}
      id="projects"
    >
      <h1 className="text-5xl font-dosis font-bold mt-24 mb-10">Projects</h1>
      {content.projects.map((project) => (
        <Project project={project}/>
      ))}
    </div>
  );
}
