export default {
  dp: process.env.PUBLIC_URL + "/assets/dp.jpg",
  nav: {
    logo: "Kartikey Raut",
    links: [
      { text: "About", to: "about" },
      { text: "Projects", to: "projects" },
      { text: "Contact", to: "contact" },
    ],
  },
  header: {
    img: "https://images.unsplash.com/photo-1555952517-2e8e729e0b44?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1300&q=80",
    imgPlaceholder: process.env.PUBLIC_URL + "/favicon.ico",
    text: ["Hey 👋", "Wassup!!", "It's me Kartikey."],
    typed: [
      "I'm a Software Engineer.",
      "I'm a Full Stack Developer.",
      "I'm Not a Youtuber.",
    ],
    btnText: "About Me",
  },
  projects: [
    {
      title: "Projects",
      img: process.env.PUBLIC_URL + "/assets/projectImages/pro4web.png",
      imgPlaceholder: process.env.PUBLIC_URL + "/favicon.ico",
      projectName: "Shopping Cart and Wishlist App",
      desc: "Shopping Cart and Wishlist App created uing ReactJs , Redux , Firebase real time database , Tailwind CSS , React Hot Toast",
      github: "https://github.com/kartikeyraut/shopping-cart-wishlist",
      demo: "https://cart-wishlist.web.app/",
    },
    {
      title: "Projects",
      img: process.env.PUBLIC_URL + "/assets/projectImages/pro3web.png",
      imgPlaceholder: process.env.PUBLIC_URL + "/favicon.ico",
      projectName: "Todo Web APP",
      desc: "To Do App created uing ReactJs , Tailwind CSS , Firebase real time database",
      github: "https://github.com/kartikeyraut/to-do",
      demo: "https://todo-4f3b1.web.app/",
    },
    {
      title: "Projects",
      img: process.env.PUBLIC_URL + "/assets/projectImages/pro2web.png",
      imgPlaceholder: process.env.PUBLIC_URL + "/favicon.ico",
      projectName: "Weather Web APP",
      desc: "Weather App Using ReactJs , Tailwind CSS , Openweathermap API",
      github: "https://github.com/kartikeyraut/weather-app",
      demo: "https://weather-app-3c677.web.app/",
    },
    {
      title: "Projects",
      img: process.env.PUBLIC_URL + "/assets/projectImages/pro1web.png",
      imgPlaceholder: process.env.PUBLIC_URL + "/favicon.ico",
      projectName: "Stopwatch",
      desc: "Stopwatch created using Reactjs , tailwind CSS",
      github: "https://github.com/kartikeyraut/stopwatch",
      demo: "https://stopwatch-ffec2.web.app/",
    },
  ],
  contact: {
    title: "Ping Me!",
    desc: "Want to work on some projects together , connect me . We will first Brainstorm & then Google it ;-)",
    socials: [
      {
        alt: "github",
        img: process.env.PUBLIC_URL + "/assets/github.png",
        link: "https://github.com/kartikeyraut",
      },
      {
        alt: "link",
        img: process.env.PUBLIC_URL + "/assets/link.png",
        link: "https://www.linkedin.com/in/kartikey-raut-a394ab15b",
      },
      {
        alt: "gmail",
        img: process.env.PUBLIC_URL + "/assets/gmail.png",
        link: "mailto: kartikeyraut5148@gmail.com",
      },
    ],
    text: ["You can find me on "],

    img: "https://images.unsplash.com/photo-1555952517-2e8e729e0b44?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1300&q=80",
    imgPlaceholder: process.env.PUBLIC_URL + "/favicon.ico",
    outro:
      "...And in case I don't see ya, Good Morning , Good afternoon, Good evening, and Good night!",
  },
  about: {
    title: "About Me",
    desc: `Hello , I'm Kartikey Raut. I've done my B.Tech in Software Engineering.
    I'm an enthusiastic individual with quick learning abilities and problem solving skills. Always looking forward to learn new things.`,
    company: {
      text: [" I'm currently working in ", "as a "],
      name: "Powerschool",
      website: "https://www.powerschool.com/",
      position: "Software Engineer",
    },
    hobbies: {
      desc: "My hobbies include solving these beauties.",
      cubes: [
        process.env.PUBLIC_URL + "/assets/rubiks2.png",
        process.env.PUBLIC_URL + "/assets/skewb2.png",
        process.env.PUBLIC_URL + "/assets/megaminx2.png",
      ],
    },
  },
  error: {
    img: process.env.PUBLIC_URL + "/assets/error.jpg",
    btnText: "Go Back",
  },
};
