import React from "react";
import { ParallaxBanner } from "react-scroll-parallax";
import timelineData from "./timelineData";
import { motion } from "framer-motion";

const rowItem = (img, date, title, data) => {
  console.log("img===", img);
  return (
    <div className="h-screen flex flex-row justify-between items-center">
      <div className="grow m-9 p-44 pr-12">
        <ParallaxBanner
          layers={[
            { image: img, speed: -30 },
            {
              speed: -15,
            },
          ]}
          style={{
            aspectRatio: "1/1",
          }}
          className="absolute inset-0 rounded-full "
        />
      </div>
      <div className="grow m-9 aspect-square">
        <ParallaxBanner
          layers={[
            {
              speed: -15,
              children: (
                <div className="absolute inset-0 bg-transparent flex flex-col items-start justify-center">
                  <div className="">
                    <div class="flex justify-start text-white text-4xl">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-16"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                          />
                        </svg>
                      </div>
                      <div className="my-auto">{date}</div>
                    </div>
                    <br />
                    <h1 className="text-6xl text-white font-semibold">
                      {title}
                    </h1>
                    <br />
                    <h1 className="text-6xl text-white font-thin">{data}</h1>
                  </div>
                </div>
              ),
            },
          ]}
          style={{
            aspectRatio: "1/1",
          }}
          className="absolute inset-0"
        ></ParallaxBanner>
      </div>
    </div>
  );
};

const playPauseButton = (isAudioPlaying, togglePlayPause) => {
  return (
    <button
      className="fixed z-40 top-4 right-4 bg-blue-500 text-white font-semibold py-2 px-4 rounded shadow"
      onClick={togglePlayPause}
    >
      {isAudioPlaying ? "Pause" : "Play"}
    </button>
  );
};

const bottomScrollIndicator = () => {
  return (
    <div className="bottom-0 left-0 right-0 animate-bounce fixed z-40 pb-12  text-white font-semibold flex justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-10"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
    </div>
  );
};

// Mix of image and video URLs
const mediaItems = [
  { type: "image", src: "/rsAssets/images/homepage/images/hpi1.jpg" },
  { type: "video", src: "/rsAssets/images/homepage/hpv1.mp4" },
  { type: "image", src: "/rsAssets/images/homepage/images/hpi2.jpg" }, // Example video
  { type: "video", src: "/rsAssets/images/homepage/hpv2.mp4" },
  { type: "image", src: "/rsAssets/images/homepage/images/hpi3.jpg" },
  { type: "video", src: "/rsAssets/images/homepage/hpv3.mp4" }, // Another example video
  { type: "image", src: "/rsAssets/images/homepage/images/hpi4.jpg" },
  { type: "video", src: "/rsAssets/images/homepage/hpv4.mp4" },
  { type: "image", src: "/rsAssets/images/homepage/images/hpi5.jpg" },
  { type: "video", src: "/rsAssets/images/homepage/hpv5.mp4" },
  { type: "image", src: "/rsAssets/images/homepage/images/hpi6.jpg" },
  { type: "video", src: "/rsAssets/images/homepage/hpv6.mp4" },
  { type: "image", src: "/rsAssets/images/homepage/images/hpi7.jpg" },
  { type: "video", src: "/rsAssets/images/homepage/hpv7.mp4" },
  { type: "image", src: "/rsAssets/images/homepage/images/hpi8.jpg" },
];

// Define varied and subtle animations
const animations = [
  {
    scale: [1, 1.05, 1],
    transition: { duration: 2, repeat: Infinity, repeatType: "loop" },
  },
  {
    y: [0, -10, 0],
    transition: { duration: 2, repeat: Infinity, repeatType: "loop" },
  },
  {
    opacity: [0.8, 1, 0.8],
    transition: { duration: 2, repeat: Infinity, repeatType: "loop" },
  },
  {
    x: [0, 10, 0],
    transition: { duration: 2, repeat: Infinity, repeatType: "loop" },
  },
  {
    scale: [1, 1.1, 1],
    rotate: [0, 5, 0],
    transition: { duration: 2, repeat: Infinity, repeatType: "loop" },
  },
  {
    scale: [1, 1.05, 1],
    transition: { duration: 2, repeat: Infinity, repeatType: "loop" },
  },
  {
    y: [0, 10, 0],
    transition: { duration: 2, repeat: Infinity, repeatType: "loop" },
  },
  {
    x: [0, -10, 0],
    transition: { duration: 2, repeat: Infinity, repeatType: "loop" },
  },
  {
    scale: [1, 1.05, 1],
    transition: { duration: 2, repeat: Infinity, repeatType: "loop" },
  },
  {
    rotate: [0, -5, 0],
    transition: { duration: 2, repeat: Infinity, repeatType: "loop" },
  },
  {
    opacity: [0.7, 1, 0.7],
    transition: { duration: 2, repeat: Infinity, repeatType: "loop" },
  },
  {
    scale: [1, 1.05, 1],
    transition: { duration: 2, repeat: Infinity, repeatType: "loop" },
  },
];

const PhotoCollage = () => {
  return (
    <div className="flex justify-center items-center min-h-screen overflow-hidden relative">
      <div className="grid grid-cols-5 grid-rows-3 gap-0 w-screen h-screen">
        {mediaItems.map((item, index) => (
          <motion.div
            key={index}
            className="overflow-hidden"
            initial={{ scale: 1 }}
            animate={animations[index % animations.length]} // Cycle through animations
          >
            {item.type === "video" ? (
              <video
                className="w-full h-full object-cover"
                src={item.src}
                autoPlay
                loop
                muted
              />
            ) : (
              <img
                src={item.src}
                alt={`Media ${index + 1}`}
                className="w-full h-full object-cover"
              />
            )}
          </motion.div>
        ))}
      </div>

      {/* Centered Text Overlay */}
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
        <h1 className="text-8xl text-white font-semibold bg-cyan-800 bg-opacity-50 rounded shadow">
          Hey There! Baby girl!
        </h1>
        <h1 className="text-5xl text-white mt-20 bg-cyan-800 bg-opacity-50 rounded shadow">
          Chalo dekhte hai apni journey.....
        </h1>
      </div>
    </div>
  );
};

export default function Data2({ isAudioPlaying, togglePlayPause }) {
  return (
    <div>
      {playPauseButton(isAudioPlaying, togglePlayPause)}
      {bottomScrollIndicator()}
      {PhotoCollage()}
      {timelineData.map((timeline) => {
        return rowItem(
          timeline.img,
          timeline.date,
          timeline.title,
          timeline.data
        );
      })}

      <div className="text-center">
        <h1 className="text-5xl text-white mt-20">
          Aaj thoda pyaar jata du kya,<br></br>
          Tum mere ho sabko bata du kya! <br></br> <br></br>
          Teri kalai jo pakad lu mai,<br></br>
          Haaye meri jaan gawa du kya!<br></br>
          <br></br>
          Tumhe likhne me din chala jayega,<br></br>
          Tumhe sochne me raat bita du kya!!!
        </h1>
      </div>

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}
