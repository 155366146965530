import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Project({ project }) {
  return (
    <div className="flex flex-col md:flex-row md:justify-around items-center w-10/12 border rounded-xl  my-4 shadow-xl">
      <div className="w-4/5 md:w-2/5 my-4">
        <img src={project.img}
          alt="Project Screenshot"
          className="h-full">
        </img>
        {/* <LazyLoadImage
          effect="blur"
          placeholder={<span>asdasdasdsad</span>}
          src={project.img}
          alt="Project Screenshot"
          className="h-full"
        /> */}
      </div>

      <div className="rounded-lg md:w-2/5">
        <div className="p-2">
          <h1 className={"inline-block m-4  font-dosis text-xl font-bold"}>
            {project.projectName}
          </h1>
          <p className={"inline-block w-11/12 m-4  text-xl font-dosis"}>
            {project.desc}
          </p>
          <div className="flex">
            <a href={project.demo} target="_blank" rel="noopener noreferrer">
              <button className="px-5 py-2 m-4 bg-black flex justify-around text-white rounded-lg shadow-2xl text-lg hover:bg-indigo-700">
                Demo
              </button>
            </a>
            <a href={project.github} target="_blank" rel="noopener noreferrer">
              <button className="px-5 py-2 ml-0 m-4 bg-black flex justify-around text-white rounded-lg shadow-2xl hover:bg-indigo-700">
                <i className="fa fa-github fa-2x text-xl "></i>
              </button>
            </a>
          </div>
          {/* <div>
                <a
                  className="px-20 py-3 m-4 bg-black text-white rounded-lg shadow-2xl"
                  href={project.demo}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Demo
                </a>
                <a
                  className="px-20 py-3 m-4 bg-black text-white rounded-lg shadow-2xl"
                  href={project.github}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-github text-lg" />
                </a>
              </div> */}
        </div>
      </div>
    </div>
  );
}
