import React, { useState } from "react";

export default function Password({password, passwordChangeHandler, onSubmitHandler}) {
  
  return (
    <div class="h-screen">
      <form class="h-3/4 flex flex-col items-center justify-center">
        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div class="sm:col-span-4">
            <label
              for="username"
              class="block text-sm font-medium leading-6 text-white"
            >
              Tum Meri kya ho?
            </label>
            <div class="mt-2">
              <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300  focus-within:ring-blue-600 sm:max-w-md">
                <input
                  type="text"
                  name="username"
                  id="username"
                  autocomplete="username"
                  value={password}
                  class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-white placeholder:text-gray-200 focus:ring-0 sm:text-sm sm:leading-6"
                  placeholder="bolo na..."
                  onChange={(event) => {
                    passwordChangeHandler(event.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-6 flex items-center gap-x-6">
          <button
            type="submit"
            class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={onSubmitHandler}
          >
            Thik hai na..
          </button>
        </div>
      </form>
    </div>
  );
}
