import React from "react";
import { Link as ScrollLink } from "react-scroll";
import content from "../data";

export default function Navbar() {
  return (
    <div
      style={{
        background: "#091C29",
      }}
      className="font-dosis fixed top-0 w-screen z-40"
    >
      <div className="flex flex-col md:flex-row items-center justify-between w-10/12  mx-auto py-3">
        <ScrollLink
          to="hero"
          smooth={true}
          className="text-3xl  font-bold text-white  cursor-pointer"
        >
          <h1>
            {content.nav.logo}
            <span className="h-3 w-3 bg-red-400 inline-block ml-2 rounded-full" />
          </h1>
        </ScrollLink>

        <div className="text-white text-xl">
          {content.nav.links.map((link, index) => {
            return (
              // <a href={`#${link.to}`} className="focus:border-b-2 focus:border-indigo-500 focus:bg-red-50 focus:pb-1" key={index}>
              //   <span className="mx-2 cursor-pointer">{link.text}</span>
              // </a>
              <ScrollLink
                // activeClass="border-b-2 border-indigo-500 pb-1 onHover:"
                // spy={true}
                to={link.to}
                smooth={true}
                key={index}
                // isDynamic={true}
                // hashSpy={true}
              >
                <span className="mx-2 cursor-pointer">{link.text}</span>
              </ScrollLink>
            );
          })}
        </div>
      </div>
    </div>
  );
}
