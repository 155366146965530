import React from "react";
import { Link } from "react-router-dom";
import useStartAnimation from "../../hook/useStartAnimation";
import data from "../data";

export default function Error() {
  const animated = useStartAnimation();

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center"
      style={{
        background: "#091C29",
      }}
    >
      <img src={data.error.img} className="w-full md:w-2/5 rounded-lg" />

      <Link to="/">
        <button
          className={` animate-bounceFirst bg-indigo-500 px-10 py-3 text-lg uppercase text-white rounded-lg mt-10 hover:bg-indigo-300 transform  ${
            animated ? "translate-y-0" : "translate-y-10 opacity-0"
          } transition duration-3500 ease-in-out`}
        >
          {data.error.btnText}
        </button>
      </Link>
    </div>
  );
}
