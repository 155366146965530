export default [
  {
    img: "/rsAssets/images/timeline/t1.jpg",
    date: "25th Feb",
    title: "Jab sab start hua",
    data: "Wo din hum pehli baar ek dusre ke close aaye the...pehle bass cuddle kiye....aur jo jo bhi kiye the",
  },
  {
    img: "/rsAssets/images/timeline/t2.jpg",
    date: "5th March",
    title: "Jab aap phirse ghar aaye the",
    data: "Maine aapka laptop le liye tha aur aap bahot ghussa ho gaye the.....phir aapko mana ke laya tha ghar",
  },
  {
    img: "/rsAssets/images/timeline/t3.jpg",
    date: "12th March",
    title: "Jab hum bhayankar makeout kiye the",
    data: "Ghar pe apan khoob chummachati kiye the...phir dhaba stories gaye the khane....phir apni aashiqui wali road pe ghumne gaye the raat ko",
  },
  {
    img: "/rsAssets/images/timeline/t4.jpg",
    date: "22th March",
    title: "Jab aapke bhai se mila tha",
    data: "Uske birthday pe leke gaye the meko kormangala",
  },
  {
    img: "/rsAssets/images/timeline/t6.jpg",
    date: "1st April",
    title: "Jab aap move in kiye the",
    data: "",
  },
  {
    img: "/rsAssets/images/timeline/t7.jpg",
    date: "20th April",
    title: "Wo apna raat ko hsr ki galiyo me ghumna....",
    data: "",
  },

  {
    img: "/rsAssets/images/timeline/t8.jpg",
    date: "26th April",
    title: "Jab apan mall gaye the",
    data: "Mall me movie dekhe the.....sizzelers khaye the",
  },
  

  {
    img: "/rsAssets/images/timeline/t9.jpg",
    date: "30th April",
    title: "Jab pehli baar sex kiye the",
    data: "Wo din jab aapne meri virginity leke khud ke paas rakh li thi.....:)",
  },

  {
    img: "/rsAssets/images/timeline/t10.jpg",
    date: "5th May",
    title: "Jab din jab me aapke liye phool laya tha",
    data: "mann to karta hai meri phool ke liye roz ek phool laau....",
  },
  ,

  {
    img: "/rsAssets/images/timeline/t11.jpg",
    date: "11th May",
    title: "Jab apan pehli date pe gaye the",
    data: "Uss din jaldi jaldi office ka kaam khatam karke gaye the KAZE .....waha jake pehle review bhare the....phir badhiya khaye piye the.",
  },
  {
    img: "/rsAssets/images/timeline/t12.jpg",
    date: "17th May",
    title: "Jab saath me baith ke Red label piye the",
    data: "",
  }
  ,
  {
    img: "/rsAssets/images/timeline/t13.jpg",
    date: "25th May",
    title: "Jab pehli baar sunset cinema dekhne gaye the",
    data: "Kuch kuch hota hai Tulika....tun nahi samjhogi",
  }
  ,
  {
    img: "/rsAssets/images/timeline/t14.jpg",
    date: "1st June",
    title: "Jab mai ghar pe nahi tha",
    data: "aapke liye rose bhijwaya tha....",
  },
  
  {
    img: "/rsAssets/images/timeline/t15.jpg",
    date: "8th June",
    title: "Jab apan pehle baar church street gaye the",
    data: "",
  },
  
  {
    img: "/rsAssets/images/timeline/t16.jpg",
    date: "21st June",
    title: "Jab Rockstar dekhne gaye the PVR gold me",
    data: "wo bhi daaru peeke....",
  },
  {
    img: "/rsAssets/images/timeline/t25.jpg",
    date: "15th July",
    title: "Jab pehli baar saath me cult gaye the",
    data: "Kitne saari cheeze try kare apan ne ..... gym jaate the pehle alag alag...phir aapne elite membership le liya saath me cukt jaane ke liye....bubua kahi ki",
  },
  
  {
    img: "/rsAssets/images/timeline/t18.jpg",
    date: "21st July",
    title: "Jab Pehli baar mandir gaye the",
    data: "",
  },
  {
    img: "/rsAssets/images/timeline/t24.jpg",
    date: "27th July",
    title: "Jab saath me yoga gaye the",
    data: "",
  },
  
  {
    img: "/rsAssets/images/timeline/t19.jpg",
    date: "15th August",
    title: "Jab aap goa gaye the",
    data: "Mai aapko airport drop karne aaya tha.....Meko bahut yaad aati thi aapke jaane ke baad......haaan bubu",
  },

  {
    img: "/rsAssets/images/timeline/t20.jpg",
    date: "19th August",
    title: "Jab goa se wapas aaye the",
    data: "Meri khusi ka to thikana hi nahi tha.......waha se meko selfie bhejti thi na....labbi kahi ki",
  },
  {
    img: "/rsAssets/images/timeline/t21.jpg",
    date: "7th Sep",
    title: "Jab Ganpati bappa moreya baithe the....",
    data: "",
  },
  
  {
    img: "/rsAssets/images/timeline/t22.jpg",
    date: "15th Sep",
    title: "Ye hum shower me chipkte hue....",
    data: "",
  },
  {
    img: "/rsAssets/images/timeline/t23.jpg",
    date: "17th Sep",
    title: "Ye hum aapka birthday manate hue....",
    data: "Sab kuch kitne time pe arrange kiye the apan....office se aake pura set kiye the.",
  },
];
