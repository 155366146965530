import React from "react";
// import Typical from "react-typical";
import { ReactTyped } from "react-typed";
import { LazyLoadImage } from "react-lazy-load-image-component";
import content from "../data";
import useStartAnimation from "../../hook/useStartAnimation";
import { Link } from "react-scroll";

export default function Hero() {
  const transition = (duration) =>
    `transition duration-${duration} ease-in-out`;
  const styleTranslate = "translate-y-10 opacity-0";
  const animated = useStartAnimation();
  return (
    <div
      style={{
        background: "#091C29",
      }}
      className="min-h-screen flex items-center justify-center"
      id="hero"
    >
      <div className="flex flex-col items-center justify-center md:flex-row-reverse md:w-10/12 md:justify-between">
        <div className="w-full md:w-2/5 ">
          <img
           src={content.dp}
           alt="profile"
           className="w-full mx-auto md:rounded-2xl"
          >
          </img>
          {/* <LazyLoadImage
            src={content.dp}
            alt="profile"
            className="w-full mx-auto md:rounded-2xl"
            effect="blur"
            placeholderSrc={content.header.imgPlaceholder}
          /> */}
        </div>

        <div className="font-dosis w-full md:w-3/5 text-center md:text-left mt-2">
          <h2
            className={`text-3xl md:text-4xl lg:text-6xl text-white  transform ${
              animated ? "translate-y-0" : "translate-y-10 opacity-0"
            }  transition duration-2000 ease-in-out `}
          >
            {content.header.text[0]}
            <br />
            {content.header.text[1]}
            <br />
            {content.header.text[2]}
          </h2>
          <h1
            className={`text-2xl md:text-4xl text-gray-400 transform ${
              animated ? "translate-y-0" : "translate-y-10 opacity-0"
            } transition duration-3000 ease-in-out `}
          >
            <ReactTyped 
              strings={content.header.typed}
              typeSpeed={50}
              backSpeed={50}
              backDelay={1500}
              loop
            />
          </h1>

          <Link to="about" smooth={true}>
            <button
              className={` animate-bounceFirst bg-indigo-500 px-10 py-3 text-lg uppercase text-white rounded-lg mt-10 hover:bg-indigo-300 transform  ${
                animated ? "translate-y-0" : "translate-y-10 opacity-0"
              } transition duration-3500 ease-in-out`}
            >
              {content.header.btnText}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
