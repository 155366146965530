import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import content from "../data";

export default function About() {
  return (
    <div
      className="min-h-screen flex items-center justify-center flex-col"
      style={{
        background: "#eee",
      }}
      id="about"
    >
      <h1 className="text-5xl font-dosis font-bold mt-24">
        {content.about.title}
      </h1>
      <p className="mt-10 mb-10 w-11/12 md:max-w-xl text-center text-xl md:text-2xl font-dosis">
        {content.about.desc}
        {/* <br />
        I'm currently seeking a job opportunity as a <b>Software Engineer</b> in a dynamic and progressive company where my skills are utilized in the maximum way possible.
        <br /> */}
        {content.about.company.text[0]}
        <a
          href={content.about.company.website}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500"
        >
          {content.about.company.name}
        </a>{" "}
        {content.about.company.text[1]}
        <b>{content.about.company.position}</b>.
        <br />
        <br />
        {content.about.hobbies.desc}
      </p>
      <div>
        <div className="flex flex-wrap justify-center">
          {content.about.hobbies.cubes.map((cube, index) => (
            <span
              key={index}
              className={` h-40 w-40 bg-white shadow-xl  ml-2  rounded-full flex justify-center items-center p-5 m-2 ${
                index % 2 !== 0 ? "animate-bounceSecond" : "animate-bounceFirst"
              }`}
            >
              <img src={cube} alt={"tech.alt"}></img>
              {/* <LazyLoadImage effect="blur" src={cube} alt={"tech.alt"} /> */}
              {console.log(cube)}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}
